module.exports = {
  siteTitle: "Seldszar",
  siteDescription: "Polyglot full-stack developer",
  siteDefaultTitle: "Seldszar - Polyglot full-stack developer",
  siteUrl: "https://seldszar.fr",
  siteDescription:
    "Self-educated polyglot full-stack developer with taste for challenge and striving for the best on every project.",

  author: "Alexandre Breteau",
  twitter: "@0xSeldszar",
};
