import { capitalize, get } from "lodash";
import { withPrefix } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import config from "../../config/website";
import { resolve } from "url";

export default ({ postNode, postPath }) => {
  const itemListElement = [];

  let description = config.siteDescription;
  let url = config.siteUrl;
  let title;
  let image;

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: config.siteUrl,
      name: config.siteTitle,
      author: {
        "@type": "Person",
        name: config.author,
      },
    },
  ];

  if (postNode) {
    const postMeta = postNode.frontmatter;

    title = `${postMeta.title} - ${capitalize(postNode.fields.sourceInstanceName)}`;
    description = postMeta.headline;
    image = get(postNode, ["fields", "cover", "childImageSharp", "resize", "src"]);
    url = resolve(config.siteUrl, withPrefix(postPath));

    if (image) {
      image = resolve(config.siteUrl, withPrefix(image));
    }

    if (postNode.fields.sourceInstanceName === "projects") {
      itemListElement.push({
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": resolve(config.siteUrl, "/projects"),
          name: "Projects",
        },
      });

      itemListElement.push({
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": url,
          name: postMeta.title,
        },
      });
    }

    if (itemListElement.length > 0) {
      schemaOrgJSONLD.push({
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement,
      });
    }

    schemaOrgJSONLD.push({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      url: url,
      headline: postNode.frontmatter.title,
      description: postNode.frontmatter.headline,
      author: {
        "@type": "Person",
        name: config.author,
      },
      image: image && {
        "@type": "ImageObject",
        url: image,
      },
    });
  }

  return (
    <Helmet defaultTitle={config.siteDefaultTitle} titleTemplate={`%s - ${config.siteTitle}`}>
      <html lang="en" />
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="description" content={description} />
      <meta name="apple-mobile-web-app-title" content={config.siteTitle} />
      <meta name="application-name" content={config.siteTitle} />
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={config.siteTitle} />
      <meta property="og:url" content={url} />
      {postNode && <meta property="og:type" content="article" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};
