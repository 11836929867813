import Footer from "components/Footer";
import Navigation from "components/Navigation";
import SEO from "components/SEO";
import { injectGlobal } from "emotion";
import { ThemeProvider } from "emotion-theming";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";
import theme from "theme";

import "typeface-roboto-slab";

injectGlobal`
  a {
    color: inherit;
  }

  body {

    @media (max-device-width: 600px) and (max-width: 600px) {
      font-size: 14px !important;

      h1 {
        font-size: 1.602rem;
      }

      h2 {
        font-size: 1.424rem;
      }

      h3 {
        font-size: 1.266rem;
      }

      h4 {
        font-size: 1.125rem;
      }
    }
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.div`
  flex: auto;
  flex-shrink: 0;
`;

export default ({ children, data, cover, seo, expanded }) => (
  <StaticQuery
    query={graphql`
      {
        socials: allSocialsYaml {
          edges {
            node {
              title
              url
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO {...seo} />
        <ThemeProvider theme={theme}>
          <Wrapper>
            <Navigation />
            <Main>{children}</Main>
            <Footer socials={data.socials} />
          </Wrapper>
        </ThemeProvider>
      </>
    )}
  />
);
