import { rgba } from "polished";

const colors = {
  lightGray: "#e6e6e6",
  mediumGray: "#cacaca",
  darkGray: "#8a8a8a",
};

export default {
  colors,
  bodyBackground: "#f0f0f0",
  bodyFontColor: "#212121",
};
