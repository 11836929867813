import { injectGlobal } from "emotion";
import { ThemeProvider } from "emotion-theming";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  border-bottom: 6px solid hsla(0, 0%, 0%, 0.8);
  padding: 4rem 2rem 1rem;
  text-align: center;

  a {
    color: inherit;
    display: inline-block;
    font-weight: bold;
    margin: 0.5rem 1rem 0;
    text-decoration: none;
    text-transform: uppercase;
  }
`;

export default ({ socials }) => (
  <Wrapper>
    {socials.edges.map((social, index) => (
      <a key={index} href={social.node.url} target="_blank" rel="noopener noreferrer">
        {social.node.title}
      </a>
    ))}
  </Wrapper>
);
