import { Link as GatsbyLink } from "gatsby";
import React from "react";

const Link = ({ children, to, ...rest }) => {
  if (/^\/(?!\/)/.test(to)) {
    return (
      <GatsbyLink to={to} {...rest}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} {...rest}>
      {children}
    </a>
  );
};

export default Link;
