import Link from "components/Link";
import React from "react";
import posed from "react-pose";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  align-items: center;
  border-top: 6px solid hsla(0, 0%, 0%, 0.8);
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  padding: 1rem 2rem 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
`;

const Logo = styled(Link)``;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  a {
    color: inherit;
    font-weight: bold;
    margin: 0 1rem 0.5rem;
    text-decoration: none;
    text-transform: uppercase;
  }
`;

const Header = () => (
  <Wrapper>
    <Nav>
      <Link to="/">Home</Link>
      <Link to="/projects">Projects</Link>
      <Link to="/doodles">Doodles</Link>
      {/* <Link to="/contact">Contact</Link> */}
    </Nav>
  </Wrapper>
);

export default Header;
