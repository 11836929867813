import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem;
`;

export default ({ children, data, cover, expanded }) => <Wrapper>{children}</Wrapper>;
